import React from "react";
import { withRouter } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBSelect,
  MDBBtn,
} from "mdbreact";

import { connect } from "react-redux";
import { showHideLoader } from "../../../actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayBreadcrumbs, showNotification } from "../../../actions";
import _ from "lodash";
import moment from "moment";
import { getLocationList } from "../../../actions/locationAction";

import { getCourtUsageReport } from "../../../apis/ReportingApi";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";

class CourtUsageReport extends React.Component {
  state = {
    startDateFilter: "",
    endDateFilter: "",
    invisibleLines: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    courtUsageLineChartData: [],
    courtUsageBarChartData: [],
    locationSelectOptions: [],
    selectedLocation: 2, //Default Tennis
    searchMemberText: "",
  };

  constructor(props) {
    super(props);
    this.state.startDateFilter = new Date(
      moment().startOf("month").format("YYYY-MM-DD")
    );
    this.state.endDateFilter = new Date(
      moment().endOf("month").format("YYYY-MM-DD")
    );
  }

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 500);

  handleLineClick = (day) => {
    this.setState((prevState) => ({
      invisibleLines: {
        ...prevState.invisibleLines, // Spread the previous state
        [day]: !prevState.invisibleLines[day], // Toggle the specific day
      },
    }));
  };

  getValueOfSelectedLocation = (value) => {
    value = value.length > 0 ? value[0] : false;

    if (value) {
      this.debouncedDoFilter();
    }

    this.setState({ selectedLocation: value });
  };

  startDateFilterOnChange = (date) => {
    this.setState({
      startDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  endDateFilterOnChange = (date) => {
    this.setState({
      endDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  componentDidMount() {
    this.props.showHideLoader(true);
    this.props.getLocationList({}, this.props.authUser.sessionToken);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Report / Court Usage"
    );
  }

  // Async function to fetch data
  getCourtUsageReportFunc = async (queryParams = {}) => {
    this.props.showHideLoader(true);
    try {
      let response = await getCourtUsageReport(queryParams);
      this.setState({
        courtUsageBarChartData: response.data.records.weekly_data_chart,
        courtUsageLineChartData: response.data.records.daily_chart_data,
      });
      // Handle the fetched data
      this.props.showHideLoader(false);
    } catch (error) {
      this.props.showNotification(
        "error",
        "Internal server error encountered. Please contact administrator."
      );
      this.props.showHideLoader(false);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getLocationListSuccessResponse !==
        this.props.getLocationListSuccessResponse &&
      this.props.getLocationListSuccessResponse.status === 200
    ) {
      const locations =
        this.props.getLocationListSuccessResponse.data.locations.results;

      const locationSelectOptions = locations.map((location) => {
        return {
          text: location.name,
          value: location.id,
        };
      });

      this.setState({ locationSelectOptions });
      this.props.showHideLoader(false);
    }
  }

  doFilter() {
    const { startDateFilter, endDateFilter, selectedLocation } = this.state;

    let params = {};

    if (startDateFilter) {
      params["start_date"] = moment(startDateFilter).format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["end_date"] = moment(endDateFilter).format("YYYY-MM-DD");
    }

    if (selectedLocation) {
      params["location_id"] = selectedLocation;
    }

    if ("location_id" in params) {
      this.getCourtUsageReportFunc(params);
    }
  }

  handleExportPDF = () => {
    const { startDateFilter, endDateFilter } = this.state;

    const input = document.getElementById("pdf-content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      const margin = 10; // Adjust this value for your desired margin

      // Add a header
      const headerText = `Court Usage Report (${moment(startDateFilter).format(
        "YYYY-MM-DD"
      )} - ${moment(endDateFilter).format("YYYY-MM-DD")})`;
      const headerFontSize = 12; // Font size for the header
      pdf.setFontSize(headerFontSize);

      // Center the header text on the page
      const textWidth = pdf.getTextWidth(headerText);
      const headerX = (pdfWidth - textWidth) / 2; // Calculate x to center the text
      const headerY = margin; // Set the Y position for the header
      pdf.text(headerText, headerX, headerY); // Add the header text at the top

      // Adjust the y-position of the image to leave space for the header
      const imageY = headerY + headerFontSize + 5; // Add some spacing after the header
      pdf.addImage(imgData, "PNG", 0, imageY, pdfWidth, pdfHeight);

      pdf.save("court-usage-report.pdf");
    });
  };

  render() {
    return (
      <MDBContainer>
        <div>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <h3 className="mt-3">Court Usage Report</h3>
            </MDBCol>
          </MDBRow>

          <MDBRow className="my-4">
            <MDBCol md="2">
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select Start Date"
                  selected={this.state.startDateFilter}
                  maxDate={this.state.endDateFilter}
                  dateFormat="dd/MM/yyyy"
                  onChange={this.startDateFilterOnChange}
                />
              </span>
            </MDBCol>
            <MDBCol md="2">
              {" "}
              <span>
                <DatePicker
                  className="custom-input"
                  placeholderText="Select End Date"
                  selected={this.state.endDateFilter}
                  minDate={this.state.startDateFilter}
                  onChange={this.endDateFilterOnChange}
                  dateFormat="dd/MM/yyyy"
                />
              </span>
            </MDBCol>

            <MDBCol md="3">
              <MDBSelect
                outline
                color="primary"
                value={this.props.selectedLocation}
                options={this.state.locationSelectOptions}
                label="Select Location"
                style={{ margin: 0 }}
                getValue={this.getValueOfSelectedLocation}
              />
            </MDBCol>

            <MDBBtn
              color="primary"
              type="button"
              size="sm"
              disabled={this.state.courtUsageLineChartData.length == 0}
              onClick={this.handleExportPDF}
            >
              Export as PDF
            </MDBBtn>
          </MDBRow>

          <div id="pdf-content">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol md="auto">
                <BarChart
                  width={1400}
                  height={600}
                  data={this.state.courtUsageBarChartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="peak_occupancy_rate"
                    name="Peak 4PM to 10:30PM"
                    fill="#332e94"
                    activeBar={<Rectangle fill="pink" stroke="blue" />}
                  />
                  <Bar
                    dataKey="off_peak_occupancy_rate"
                    name="Off Peak 4PM to 6PM"
                    fill="#f56207"
                    activeBar={<Rectangle fill="gold" stroke="purple" />}
                  />
                </BarChart>
              </MDBCol>
            </MDBRow>

            <MDBRow className="mx-4">
              <MDBTable responsive striped bordered>
                <MDBTableHead>
                  <tr>
                    <th className="text-center" width="20%">
                      Court Used 4:00pm - 6:00pm
                    </th>

                    <th className="text-center" width="20%">
                      Court Used 4:00pm - 10:30pm
                    </th>

                    <th className="text-center" width="20%">
                      Day
                    </th>

                    <th className="text-center" width="20%">
                      Occupancy Rate(%) 4:00pm - 6:00pm
                    </th>

                    <th className="text-center" width="20%">
                      Occupancy Rate(%) 4:00pm - 10:30pm
                    </th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {this.state.courtUsageBarChartData.map((data) => {
                    return (
                      <>
                        <tr>
                          <td>{data.off_peak}</td>
                          <td>{data.peak}</td>
                          <td>{data.day}</td>
                          <td>{data.off_peak_occupancy_rate}%</td>
                          <td>{data.peak_occupancy_rate}%</td>
                        </tr>
                      </>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </MDBRow>

            <MDBRow>
              <MDBCol md="12">
                <h4 className="mt-3">
                  Court Usage(%) Report By Days and Time (30 Mins Interval)
                </h4>
              </MDBCol>
            </MDBRow>
            <MDBRow className="d-flex justify-content-center">
              <MDBCol md="auto">
                <LineChart
                  width={1400}
                  height={600}
                  data={this.state.courtUsageLineChartData}
                >
                  <CartesianGrid strokeDasharray="6 6" />
                  <XAxis dataKey="timeslot" padding={{ left: 30, right: 30 }} />
                  <YAxis domain={[0, 100]} />
                  <Tooltip />
                  <Legend
                    onClick={({ value }) => this.handleLineClick(value)}
                  />

                  <Line
                    hide={this.state.invisibleLines.Monday}
                    type="monotone"
                    dataKey="Monday"
                    stroke="#064e21"
                  />
                  <Line
                    hide={this.state.invisibleLines.Tuesday}
                    type="monotone"
                    dataKey="Tuesday"
                    stroke="#d0a407"
                  />
                  <Line
                    hide={this.state.invisibleLines.Wednesday}
                    type="monotone"
                    dataKey="Wednesday"
                    stroke="#ff0000"
                  />
                  <Line
                    hide={this.state.invisibleLines.Thursday}
                    type="monotone"
                    dataKey="Thursday"
                    stroke="#82ca9d"
                  />
                  <Line
                    hide={this.state.invisibleLines.Friday}
                    type="monotone"
                    dataKey="Friday"
                    stroke="#0a2815"
                  />
                  <Line
                    hide={this.state.invisibleLines.Saturday}
                    type="monotone"
                    dataKey="Saturday"
                    stroke="#ff0582"
                  />
                  <Line
                    hide={this.state.invisibleLines.Sunday}
                    type="monotone"
                    dataKey="Sunday"
                    stroke="#000000"
                  />
                </LineChart>
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    getLocationListSuccessResponse: state.getLocationListSuccessResponse,
    getLocationListFailedResponse: state.getLocationListFailedResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    showNotification,
    getLocationList,
  })(CourtUsageReport)
);
